<template>
  <div>
    <v-form ref="form" v-model="formValid">
      <v-row dense>
        <v-col cols="8">
          <v-text-field v-model="location.name" :rules="[rules.required]" label="Location Name" filled dense/>
        </v-col>
        <v-col>
          <v-text-field v-model="formattedNumber"
                        type="text"
                        label="* Phone"
                        :rules="[rules.phone]"
                        filled dense hide-details="auto"
                        v-mask="'(###) ###-####'" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="8">
          <v-text-field v-model="location.address.address_line1" :rules="[rules.required]" label="Address Line 1" filled dense/>
        </v-col>
        <v-col>
          <v-text-field v-model="location.address.address_line2" label="Address Line 2" filled dense/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="4">
          <v-text-field v-model="location.address.city" :rules="[rules.required]" label="City" filled dense/>
        </v-col>
        <v-col cols="4">
          <StatePicker ref="statePicker" :state="location.address.state" :rules="[rules.required]" @state-change="setState" />
        </v-col>
        <v-col>
          <v-text-field v-model="location.address.zip" :rules="[rules.required]" label="Zip" hint="5 digits" filled dense/>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn text @click="cancel">Cancel</v-btn>
          <v-btn depressed color="primary" @click="save" class="ml-2" :loading="loading">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import RulesMixin from '@/mixins/RulesMixin'
import StatePicker from '@/components/FormFields/StatePicker'
import structuredClone from '@ungap/structured-clone'

const initialLocation = {
  id: null,
  name: null,
  address: {
    address_line1: null,
    address_line2: null,
    city: null,
    state: null,
    zip: null
  },
  phone_number: {
    area_code: null,
    number: null
  },
  loading: false
}
export default {
  name: 'LocationForm',
  mixins: [RulesMixin],
  components: { StatePicker },
  props: {
    initialLocation: {
      required: false,
      type: Object,
      default: () => Object.assign({}, initialLocation)
    }
  },
  data: () => ({
    formValid: true,
    formattedNumber: null,
    location: {},
    loading: false
  }),
  created () {
    this.location = structuredClone(this.initialLocation)
    if (this.location.phone_number.area_code !== null && this.location.phone_number.number !== null) {
      const number = this.location.phone_number.number.toString().replace(/(\d{3})(\d{4})/, '$1-$2')
      this.formattedNumber = `(${this.location.phone_number.area_code}) ${number}`
    }
  },
  methods: {
    async save () {
      this.loading = true

      this.$refs.form.validate()
      if (this.formValid !== true) {
        this.loading = false
        return
      }

      // break the phone number into separate parts
      if (this.formattedNumber) {
        const matches = this.formattedNumber.match(/(\(\d{3}\)) (\d{3}-\d{4})/)
        this.location.phone_number.area_code = matches[1].replace(/[()]/g, '')
        this.location.phone_number.number = matches[2].replace('-', '')
      }

      this.$emit('save', this.location)
      this.reset()
    },
    cancel () {
      this.reset()
      this.$emit('cancel')
    },
    setState (value) {
      this.location.address.state = value
    },
    reset () {
      this.location = {
        address: {},
        phone_number: {}
      }
      this.$refs.statePicker.clearState()
    }
  }
}
</script>
